.subcategoryContent{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-left: 20px;
    margin : 20px;
}
.subcategoryContent .grid{
    /* display: -ms-inline-grid; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    text-align: center;
    gap: 15px;
    justify-items: center;
    width: 100%;
    z-index: 1;
    border-radius: 20px;
}

.subcategoryContent .grid .gridContents .edit{
    position: absolute;
    top: 70px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: #ff0000;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 100;
    font-size: 18px;
    color: #fff;
    transition: .5s;
}

.subcategoryContent .grid .gridContents .edit:hover{
    position: absolute;
    top: 70px;
    right: 10px;
    width: 50px;
    height: 50px;
    /* background-color: rgb(112, 191, 217); */
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 100;
    font-size: 18px;
    color: #000;
    
}

.subcategoryContent .grid .gridContents .remove{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: red;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 100;
    font-size: 18px;
    color: white;
    transform: .5s;
}

.subcategoryContent .grid .gridContents .remove:hover{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 50px;
    height: 50px;
    /* background-color: red; */
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%);
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 100;
    font-size: 18px;
    color: #000;
}



.subcategoryContent .grid .gridContents{
    background-color: white;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    box-shadow: 1px 1px 1px 1px #99aff1;

}

.subcategoryContent .grid .gridContents img{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.subcategoryContent .grid .gridContents .icon{
    font-size: 4rem;
    padding: 20px;
}
.subcategoryContent .grid .gridContents .let{
    padding-right: 20px;
    padding: 10px;
    transition: .5s;
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%);
    cursor: pointer;
}
.subcategoryContent .grid .gridContents .let:hover{
    cursor: pointer;

    padding-right: 20px;
    padding: 10px;
    /* background-color: rgb(112, 191, 217); */
    /* color: white; */
}
.subcategoryContent .grid .gridContents h1{
    font-size: 1rem;
}

.subcategoryContent .addButton{
    position: fixed;
    display: flex;
    bottom: 20px;
    right: 30px;
    z-index: 100;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    /* padding: 10px; */
    background-color: red;
    border-radius: 50%;
    font-size: 25px;
    color: white;
}

.subcategoryContent .addButton:hover{
    /* background-color: rgb(122, 10, 10); */
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%);
    color: black;

}

/* .subcategoryContent .popup.showpopup {
    display: none;
} */

.subcategoryContent .popup{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /* background-color: black;
    opacity: .6;
    overflow-y: hidden; */
    /* filter: grayscale(50%); */
    /* color: white; */
}

.subcategoryContent .popup .innerPopup{
    width: 400px;
    height: 200px;
    background-color: white;
    opacity: 1;
    justify-content: space-around;
    padding: 5px;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20%;
}

.subcategoryContent .popup .innerPopup h1{
    font-size: 24px;
    padding-left: 20px;
    
}

.subcategoryContent .popup .innerPopup .buttonDiv{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 35px;
    width: 60%;
    gap: 20px;
    margin-left: 30%;
}
.subcategoryContent .popup .innerPopup .buttonDiv button{
    /* background-color: red; */
    border-radius: 10px;
}
.subcategoryContent .popup .innerPopup .buttonDiv button:nth-child(1){
    background-color: rgb(246, 103, 56);
    color: white;
    border-radius: 10px;
}
.subcategoryContent .popup .innerPopup .buttonDiv button:nth-child(1):hover{
    background-color: red;
    color: white;
    border-radius: 10px;
}
.subcategoryContent .popup .innerPopup .buttonDiv button:nth-child(2):hover{
    background-color: grey;
    border-radius: 10px;
    color: white;
}
@media only screen and (max-width: 1000px){
    .subcategoryContent .grid{
        /* display: -ms-inline-grid; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        text-align: center;
        gap: 15px;
        justify-items: center;
        width: 100%;
    }
}

@media only screen and (max-width: 900px){
    .subcategoryContent .grid{
        /* display: -ms-inline-grid; */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        text-align: center;
        gap: 15px;
        justify-items: center;
        width: 100%;
    }
}


.gif_loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    flex-direction: column;
}