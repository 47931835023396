.addsubcategorycontainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    align-items: center;
    border-radius: 12px;
border: 1px solid #DBDBDB;
background: #FFF;
padding: 12px;
    /* position: relative; */
    /* background-color: red; */
}
.subcategoryflex{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
    box-sizing: border-box;

}

.subcategoryflex:nth-child(2){
    margin-top: 20px;
    align-items: start;
    padding-left: 10px;
    padding-right: 10px;
}

.addsubcategorycontainer .subcategoryflex img{
    width: 300px;
    height: auto;
}

/* .addsubcategorycontainer .subcategoryflex .imageSlider{
    display: flex;
    align-items: center;
    justify-items: center;
} */
.cropbtn{
    /* width: 40%; */
    padding: 10px;
    border-radius: 0px;
    background-color :white;
    font-size: 16px;
    gap: 10px;
}

.cropbtnbrowse{
    width: 100%;
    padding: 10px;
    border-radius: 0px;
    background-color :white;
    font-size: 16px;
    color: black;
}
.cropbtnbrowse:hover{
    background-color: rgb(0,140,186);
    color: white;
}

/* .cropbtn:hover{
    background-color: rgb(0,140,186);
    color: white;
    
} */
.cropbtn :hover{
    background: linear-gradient(90deg, #290352 0%, #052546 35.5%, #011021 100%) !important; /* Rotate gradient */
    transform: scale(1.05) !important; /* Optional scaling effect */
}
.btngrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.addsubcategorycontainer .subcategoryflex .imageSlider .addPng{
    width: 50px;
    height: auto;
    border-radius: 50%;
}

.addsubcategorycontainer .subcategoryflex .imageSlider .imageContain{
    position: relative;
}
.addsubcategorycontainer .subcategoryflex .imageSlider .imageContain .removeIcon{
    position: absolute;
    top: 5px;
    right: 0;
    color: red;
}

.addsubcategorycontainer .subcategoryflex .editorImg{
    width: 450px;
    height: auto;
    transition: .5s;
}

.addsubcategorycontainer .subcategoryflex .editorImg:hover{
    scale: 1.1;
}

.displayContent{
    display: flex;
    align-items: start;
    flex-direction: column;
    line-height: 2.6rem;
}

/* .addsubcategorycontainer .subcategoryflex .imageSlider{
    display: flex;
    gap:5px;
    margin: 20px;
} */

.addsubcategorycontainer .subcategoryflex .imageSlider img{
    width: 100px;
    height: auto;
}

/* .addsubcategorycontainer .iconContainer{
    background-color: red;
color: white !important;
padding: 20px;
font-size: 16px;
    position: absolute;
    bottom: 50px;
    right:30px;
    border-radius: 50px;
    
} */
.addsubcategorycontainer .iconContainer {
    background-color: red;
    color: white !important;
    padding: 20px;
    font-size: 20px;
    position: absolute;
    bottom: 50px;
    right: 30px;
    border-radius: 50%; /* Makes it a circle */
    width: 60px; /* Set a fixed width */
    height: 60px; /* Set a fixed height equal to width */
    display: flex; /* Use flex to center the content */
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
}

.addsubcategorycontainer .iconContainer:hover{
    /* background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%); */
    background: linear-gradient(180deg, #290352 0%, #052546 35.5%, #011021 100%);


    color: #fff !important;
}

.addsubcategorycontainer .subcategoryflex .contentdiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    box-sizing: border-box;
}

.addsubcategorycontainer .subcategoryflex .imageSlider {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    flex-basis: 50%;
    /* background-color: red; */
}


.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow {
    display: flex;
    
   /* align-items: center; */

    padding: 15px 0px;
    width: 100%;
    transition: background-color 0.3s ease, border-radius 0.3s ease;
    flex-direction: column;
    /* background-color: red; */
}

.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow input, 
.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow textarea {
    /* display: flex;
    flex-direction: column; */
    width: 100%;
    max-width: 280px;
    margin-right: 20px;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 10px !important;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    border-radius: 4px;
border: 0.8px solid #B3B3B3;
background: #EEE;
}

.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow input::placeholder,
.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow textarea::placeholder {
    color: #999; 
    font-size: 14px; 
    opacity: 1; /* Set opacity to full for better visibility */
}


.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow input:focus, 
.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow textarea:focus {
    border-color: #4a90e2 !important;
    /* box-shadow: 0px 0px 5px rgba(74, 144, 226, 0.5); */
}

.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow label {
    font-size: 18px;
    color: #000;
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.required-asterisk{
    color: red !important;
}

.addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow label .required {
    /* color: red; */
    margin-left: 5px;
}

@media only screen and (max-width : 1050px){
    .addsubcategorycontainer{
        flex-direction: column;
        align-items: center;
        
    }
    .addsubcategorycontainer .subcategoryflex{
        align-items: center;
    }

    .addsubcategorycontainer .subcategoryflex .contentdiv{
        align-items: center;
        width: 100%;
    }
    .addsubcategorycontainer .subcategoryflex .imageSlider img{
        width: 80px;
        height: auto;
    }
    .addsubcategorycontainer .subcategoryflex .imageSlider{
        gap: 15px;
    }
    /* .addsubcategorycontainer .subcategoryflex .contentdiv {
        align-items: center;
    } */
    .addsubcategorycontainer .subcategoryflex .contentdiv .contentdivrow {
        /* display: flex; */
        align-items: center;
    }
}




.gif_loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    flex-direction: column;
}