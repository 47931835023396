.mainContent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-left: 20px;
    margin : 20px;
    overflow-x: hidden;
}
.mainContent .grid{
    /* display: -ms-inline-grid; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 1fr;
    text-align: center;
    gap: 15px;
    justify-items: center;
    width: 100%;
    padding: 5px;
}

.mainContent .grid .gridContents{
    background-color: white;
    /* margin-top: 10px; */
    margin-left: 10px;
    margin-right: 10px;
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    box-shadow: 1px 1px 1px 1px #99aff1;

}

.mainContent .grid .gridContents .icon{
    font-size: 4rem;
    padding: 20px;
}
.mainContent .grid .gridContents .let{
    padding-right: 20px;
}
.mainContent .grid .gridContents h3{
    font-size: 1rem;
}

@media only screen and (max-width: 1300px){
    .mainContent .grid{
        /* display: -ms-inline-grid; */
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        text-align: center;
        gap: 15px;
        justify-items: center;
        width: 100%;
    }
}

@media only screen and (max-width: 1000px){
    .mainContent .grid{
        /* display: -ms-inline-grid; */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        text-align: center;
        gap: 15px;
        justify-items: center;
        width: 100%;
    }
}
@media only screen and (max-width: 600px){
    .mainContent .grid{
        /* display: -ms-inline-grid; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 1fr;
        text-align: center;
        gap: 15px;
        justify-items: center;
        width: 100%;
    }
}