.rightdiv{
    flex: 8;
    /* padding-left: 2px; */
    /* background-color: #cfe2ef; */
    /* background-color: rgb(209, 208, 208); */
    position: relative;
    transition: .5s;
    overflow-y: auto;
}

.drawer {
    display: none;
    align-items: center;
    /* left: 0px; */
}
.nav img{
    width: 40px;
    height: auto;
    border-radius: 50%;
}
/* .sideDrawer{
    display: none;
} */

.drawer img{
    width:50px ;
    height: auto;
    
}
.nav_search_all{
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 21px;
    /* background: #e8dede66; */
    border-radius: 21px;
background: #F8F8F8;
    border: none !important;
    padding: 10px 24px;
    

}
/* .welcome_text h1{
    color: black !important;
    font-size: 24px;
    background: linear-gradient(90deg, #F27D7F 0%, #9960D2 100%);
} */


.welcome_text h1 {
    font-size: 24px;
    background: linear-gradient(90deg, #F27D7F 0%, #9960D2 100%);
    -webkit-background-clip: text;
    color: transparent;
}


.rightdiv .nacinput{
    border: none !important;
    outline: none;
    background-color: transparent !important;
    /* width: 300px;
    padding: 10px;
    border-radius: 20px; */
  
    
}
.nav{
    position: sticky;
    top: 0px;
    z-index: 100;
    /* height: 54px; */
    display: flex;
    /* background-color: red; */
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 2%;
    align-items: center;
    color: white;
    /* background-color: rgb(0,140,186); */
    /* box-shadow: 10px 10px 8px #888888; */
    /* background: linear-gradient(124deg, #FBFBFB 11.46%, #E7E0F1 101.15%); */
    background-color: #E7E0F1 !important;
}

.nav ul li{
    flex: 1;
    list-style: none;
    justify-content: center;
    display: inline;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 10px;
    font-size: 16px;
    color: white;
    transition: .5s;
    /* background-color: white;
    border-radius: 50%; */
    /* color: black; */
}
/* .nav ul li:hover{
    list-style: none;
    justify-content: center;
    display: inline;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 10px;
    font-size: 20px;
    color: white;
   
} */

.rightdiv .sideDrawer{
    position: fixed;
    width: 50%;
    height: 100vh;
    background-color: rgb(247, 242, 242);
    top: 0px;
    left: 0px;
    z-index: 5;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    
    
}

.rightdiv .sideDrawer .top{
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    background-color: rgb(0,140,186);
    align-items: center;
    color: white;
}
.rightdiv .sideDrawer .top h1{
    margin-left: 10px;
    font-size: 24px;
}
.rightdiv .sideDrawer .top img{
    width: 30px;
}
.rightdiv .sideDrawer.openDrawer{
    display: none;
}

.rightdiv .sideDrawer ul{
    margin-left: 20px;
    margin-top: 50px;
    margin-right: 20px;
    
}

.rightdiv .sideDrawer ul li{
    list-style: none;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    /* border-bottom: solid .5px grey; */
    /* box-shadow: 0 2 4 4 black; */
    font-size: 18px;
    transition: .3s;
    color: blue;
    
}
.rightdiv .sideDrawer ul li:hover{
    list-style: none;
    padding-top: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    color: white;
    font-size: 20px;
    background-color: rgb(112, 191, 217);
}

.logout{
    display: flex;
    position: absolute;
    bottom: 0;
    gap: 20px;
    align-items: center;
    background-color: rgb(0,140,186);
    color: white;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    padding-left: 20px;
    justify-content: space-between;
    padding-right: 20px;
}

.logout h1{
    font-size: 24px;
}

@media only screen and (max-width : 800px){
    .drawer{
        display: flex;
        
    }
    .drawer h1{
        padding-left: 10px;
        font-size: 26px;
    }

    .rightdiv{
        flex: 8;
        /* background-color: white; */
        position: relative;
        transition: .5s;
        overflow-y: auto;
    }
    .rightdiv .nacinput{
        width: 150px;
        padding: 10px;
        border-radius: 20px;
        
    }
    .drawer img{
        width:30px ;
        height: auto;
        
    }
    .nav ul li{
        list-style: none;
        display: inline;
        padding: 10px;
        font-size: 18px;
    }
}

@media only screen and (max-width:600px){
    .rightdiv .nacinput{
        display: none;
    }

    .rightdiv .sideDrawer{
        position: fixed;
        width: 70%;
        height: 100vh;
        background-color: rgb(247, 242, 242);
        top: 0px;
        left: 0px;
        z-index: 5;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
    }
    
}

@media only screen and (min-width: 800px){
    .rightdiv .sideDrawer{
        display: none;
        
    }
    
}





















/* Container for the dropdown */
.dropdown-container {
    position: relative;
    display: inline-block;
    z-index: 10;
  }
  
  /* Profile image */
  .profile-image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  
  /* Dropdown menu */
  .dropdown-menu {
    display: none; /* Initially hidden */
    position: absolute;
    top: 45px; /* Just below the image */
    right: 0; /* Align to the right of the image */
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    z-index: 10;
    padding: 10px 0;
    width: 150px;
  }
  
  /* Dropdown "pin" effect */
  .dropdown-menu::before {
    content: '';
    position: absolute;
    top: -10px; /* Place the pin at the top of the box */
    right: 20px; /* Center align with the dropdown box */
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 10px solid white; /* Same color as the dropdown background */
    z-index: 11;
  }
  
  /* Dropdown menu items */
  .dropdown-menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .dropdown-menu ul li {
    display: flex;
    padding: 10px 15px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
  }
  
  .dropdown-menu ul li:hover {
    background: #f0f0f0;
  }
  
  /* Ensure dropdown stays visible when hovering over the menu or image */
  .dropdown-container:hover .dropdown-menu {
    display: block;
  }
  
  /* Dropdown remains visible even when hovering the menu */
  .dropdown-container .dropdown-menu:hover {
    display: block;
  }
  