.categoryEditContainer{
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #f9f9f9;
}

.categoryEditContainer .innderEditor{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2%;
    padding: 3% 2%;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.categoryEditContainer .innderEditor:hover{
    transform: scale(1.02);
    box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
}

.categoryEditContainer .innderEditor .imageEditor{
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.categoryEditContainer .innderEditor .imageEditor img{
    width: 300px;
    height: auto;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.categoryEditContainer .innderEditor .imageEditor img:hover{
    transform: scale(1.05);
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}

.categoryEditContainer .innderEditor .contentdiv{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
}

.categoryEditContainer .innderEditor .contentdiv .contentdivrow{
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: center;
    width: 100%;
    transition: background-color 0.3s ease;
}

.categoryEditContainer .innderEditor .contentdiv .contentdivrow:hover{
    background-color: #f1f1f1;
    border-radius: 10px;
}

.categoryEditContainer .innderEditor .contentdiv .contentdivrow h1, 
.categoryEditContainer .innderEditor .contentdiv .contentdivrow p{
    margin: 0;
    padding-right: 20px;
    font-size: 24px;
    color: #333;
    transition: color 0.3s ease;
}

.categoryEditContainer .innderEditor .contentdiv .contentdivrow input, 
.categoryEditContainer .innderEditor .contentdiv .contentdivrow textarea{
    width: 100%;
    max-width: 300px;
    margin-right: 20px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.categoryEditContainer .innderEditor .contentdiv .contentdivrow input:focus, 
.categoryEditContainer .innderEditor .contentdiv .contentdivrow textarea:focus{
    border-color: #4a90e2;
    box-shadow: 0px 0px 5px rgba(74, 144, 226, 0.5);
}

.categoryEditContainer .imgCroper{
    margin-top: 20px;
    width: 100%;
    animation: fadeIn 0.5s ease;
}

.categoryEditContainer .imgCroper button{
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.categoryEditContainer .imgCroper button:hover{
    background-color: #357abd;
}

.categoryEditContainer .innderEditor .saveIcon{
    font-size: 24px;
    color: #4a90e2;
    transition: color 0.3s ease;
}

.categoryEditContainer .innderEditor .saveIcon:hover{
    color: #357abd;
}


@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
