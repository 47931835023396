

.rightdiv{
    flex: 12;
}

.rightdiv .rightnav{
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightdiv .leftnav{
    display: flex;
    align-items: center;
    justify-content: center;
}

.rightdiv .rightnav .notification, .profile{
    display: flex;
    gap: 20px;
}

.rightdiv nav ul li{
    display: inline;
    list-style: none;
    
}

.content-wrapper{
    background-color: var(--bg);
    padding: 20px;
    /* width: 100%;
    height: 100vh; */
}

.content-wrapper h1{
    font-size : 32px;
    padding: 0;
}

.cards{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
    grid-template-rows: 1fr;
}

.card{
    position: relative;
    background-color: white;
    padding: 20px;
    align-items: center;
    border-radius: 10px;

}
.card p{
    font-size: 12px;
    color: var(--grey);
}
.card h4{
    padding-top: 10px;
    font-size: 28px;
    font-weight: bold;
}
.card img{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
}
.card .status{
    padding-top: 20px;
    color: var(--black);
}


.title{
    padding: 10px;
    display: flex;
    justify-content: space-between;
    
}
.title h1{
    font-size: 18px;
}

.graph{
    background-color: var(--white);
    padding: 10px;
    margin-top: 20px;
    border-radius: 10px;
}

.graph img{
    margin-top: 10px;
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media only screen and (max-width:1000px) {
    .cards{
        grid-template-columns: repeat(3,1fr);
    }
}
@media only screen and (max-width:870px) {
    .cards{
        grid-template-columns: repeat(2,1fr);
    }
}
@media only screen and (max-width:700px) {
    .leftdiv.inactive{
        display: none;
    }
}
@media only screen and (max-width:450px) {
    .cards{
        grid-template-columns: repeat(1,1fr);
    }
}

.BG{
    background-image: url('../../Images/BG.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 155px;
}
.BG1{
    background-image: url('../../Images/BG1.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 155px;
}
.BG2{
    background-image: url('../../Images/BG2.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 155px;
}
.cards_all{
    border-radius: 16px;
border: 1px solid #F6F6F6;
background: #FFF;
box-shadow: 0px 4px 20px 0px rgba(235, 235, 235, 0.50);
padding-top: 15px;
padding-bottom: 15px;
}
.bg_des{
    padding-left: 30px;
    padding-top: 20px;
}


.bg_des h3{
    color: #666;
/* font-family: "Public Sans";   */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 14px; /* 100% */
}
.bg_des p{
    color: #000;
/* font-family: "Public Sans"; */
font-size: 36px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.bg_des h6{
    color: #666;
/* font-family: "Public Sans"; */
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 14px;
}
.bg_des span{
    color: #18C329;
/* font-family: "Public Sans"; */
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 14px; /* 100% */
}


.BG3{
    background-image: url('../../Images/BG3.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 155px;
}

.BG4{
    background-image: url('../../Images/BG4.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 155px;
}

.BG5{
    background-image: url('../../Images/BG5.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 155px;
}



/* Customers */
.recent_customer_all{
    border-radius: 16px;
border: 1px solid #F6F6F6;
background: #FFF;
box-shadow: 0px 4px 20px 0px rgba(235, 235, 235, 0.50);
padding: 12px;
}
.recent_head{
    display: flex;
    align-items: center;
    justify-content: space-between !important;
}
.recent_head h6{
    color: #000;
/* font-family: "Public Sans"; */
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
margin: 0;
}
.recent_head p{
    margin: 0;
    color: #666;
/* font-family: "Public Sans"; */
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.custom_hr{
    background: #EBEBEB;
    height: 1px;
}
.recent_customer_details_all{
    height: 300px !important;
    overflow-y: scroll !important;
}


.recent_customer_details{
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    /* padding-top: 12px;
    padding-bottom: 12px; */
}
.recent_img{
    display: flex;
    align-items: center;
    gap: 40px;
}
.recent_detailed p{
    color: #000;
/* font-family: "Public Sans"; */
font-family: jost;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
margin: 0;
padding-bottom: 8px;

}
.recent_detailed h3{
margin: 0;

    color: #000;
    /* font-family: "Public Sans"; */
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    /* padding-bottom: 4px; */
}
.recent_detailed h6{
margin: 0;

    color: #666;
/* font-family: "Public Sans"; */
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.recent_time p{
    margin: 0;
    color: #666;
/* font-family: "Public Sans"; */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
padding-bottom: 8px;
}
.recent_time h2{
    color: #666;
/* font-family: "Public Sans"; */
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
}













.recent_customer_details_all {
    height: 200px;
    overflow-y: scroll !important;
}

/* Custom scrollbar styling */
.recent_customer_details_all::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.recent_customer_details_all::-webkit-scrollbar-track {
    background: linear-gradient(180deg, #cbc7ce 0%, #3b6896 18.23%, #022852 100%); /* Track gradient background */
    border-radius: 5px; /* Rounded edges for track */
}

.recent_customer_details_all::-webkit-scrollbar-thumb {
    /* background: linear-gradient(180deg, #9960D2 0%, #F27D7F 100%);  */
    background: linear-gradient(180deg, #290352 0%, #052546 18.23%, #011021 100%); /* Track gradient background */

    border-radius: 8px; /* Rounded edges for thumb */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle shadow to the thumb */
}

.recent_customer_details_all::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #F27D7F 0%, #9960D2 100%); /* Invert colors on hover for a striking effect */
}















.table-container {
    width: 100%;
    overflow-x: auto;
    margin: 20px 0;
    height: 300px !important;
    overflow-y: scroll !important;
}

.product-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-family: 'Poppins', sans-serif;
   
}

.product-table th, .product-table td {
    padding: 10px 15px;
    border: 1px solid #ddd;
}

.product-table th {
    background: linear-gradient(180deg, #290352 0%, #052546 35.5%, #011021 100%);
    color: white;
}

.product-table td {
    background: #f9f9f9;
}

.product-image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
}

.product-table tr:hover {
    background: rgba(118, 92, 235, 0.2);
}

.in-stock {
    color: green;
    font-weight: bold;
}

.out-of-stock {
    color: red;
    font-weight: bold;
}

.low-stock {
    color: orange;
    font-weight: bold;
}









/* Custom scrollbar styling */
.table-container::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
}

.table-container::-webkit-scrollbar-track {
    background: linear-gradient(180deg, #cbc7ce 0%, #3b6896 18.23%, #022852 100%); /* Track gradient background */
    border-radius: 5px; /* Rounded edges for track */
}

.table-container::-webkit-scrollbar-thumb {
    /* background: linear-gradient(180deg, #9960D2 0%, #F27D7F 100%);  */
    background: linear-gradient(180deg, #290352 0%, #052546 18.23%, #011021 100%); /* Track gradient background */

    border-radius: 8px; /* Rounded edges for thumb */
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3); /* Add a subtle shadow to the thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(180deg, #F27D7F 0%, #9960D2 100%); /* Invert colors on hover for a striking effect */
}
