.userListContainer{
    padding: 10px;
}
.tableHeader{
    display: flex;
    justify-content: space-between;
    border: solid .5px grey;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: white; */
    background: linear-gradient(180deg, #290352 0%, #052546 35.5%, #011021 100%);
color: white !important;
}
.tableHeader h1{
    
    padding-left: 10px;
    padding-right: 10px;
    font-size: 24px;
}

.tableHeader h1:nth-child(1){
    flex: 4;
}

.tableHeader h1:nth-child(2){
    flex: 2;
    border-left: solid .5px grey;
}

.tableHeader h1:nth-child(3){
    flex: 1;
    border-left: solid .5px grey;
}


.tableRow{
    display: flex;
    justify-content: space-between;
    border: solid .5px grey;
    align-items: center;
    background-color: white;
}
.tableRow h3{
    
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
    font-weight: normal;
    align-items: center;
    justify-content: center;
    font-weight: 200;
}

.tableRow:nth-child(odd){
    /* background-color: rgb(206, 228, 235); */
    border-radius: 16px;
background: #F5F5F5;
}
.tableRow:nth-child(even){
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 16px;
}

.tableRow h3:nth-child(1){
    flex: 4;
}

.tableRow h3:nth-child(2){
    flex: 2;
    border-left: solid .5px grey;
}
.tableRow div:nth-child(3){
    flex: 1;
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    width: 40px;
    height: auto;
    border-left: solid .5px grey;
    justify-content: center;
    padding: 5px;
    
}

.tableRow img{
    /* flex: 2; */
    width: 30px;
    height: auto;
    border-radius: 50%;
}

@media only screen and (max-width:1000px){
    .tableHeader h1{
    
        padding-left: 10px;
        padding-right: 10px;
        font-size: 18px;
    }

    .userListContainer{
        padding: 10px;
    }
    .tableRow h3{
    
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        font-weight: normal;
        align-items: center;
        justify-content: center;
        font-weight: 200;
    }
}

@media only screen and (max-width:600px){
    .tableHeader h1{
    
        padding-left: 10px;
        padding-right: 10px;
        font-size: 16px;
        font-weight: 400;
    }
    
    
}