.addproductcontainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /* position: relative; */
    /* background-color: red; */
}
.productflex{
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    /* justify-content: center; */
    align-items: center;
    box-sizing: border-box;

}

.productflex.left{
    flex: 2;

}
.productflex.right{
    flex: 3;
    
}

.productflex:nth-child(2){
    margin-top: 20px;
    align-items: start;
    padding-left: 10px;
    padding-right: 10px;
}

.addproductcontainer .productflex img{
    width: 300px;
    height: auto;
}

.addproductcontainer .productflex .imageSlider{
    display: flex;
    align-items: center;
    justify-items: center;
}
.cropbtn{
    margin-bottom: 15px;
    margin-top: 15px;
    width: 50%;
    padding: 10px;
    border-radius: 0px;
    color: #fff;
    font-size: 16px;
    border-radius: 24px;
background: linear-gradient(180deg, #290352 0%, #052546 35.5%, #011021 100%);
transition: background 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */

}
.cropbtn :hover{
    background: linear-gradient(90deg, #290352 0%, #052546 35.5%, #011021 100%) !important; /* Rotate gradient */
    transform: scale(1.05) !important; /* Optional scaling effect */
}

.cropbtnbrowse{
    border-radius: 18px;
background: #DE2424;
    width: 100%;
    padding: 10px;
    /* border-radius: 0px; */
    /* background-color :white; */
    font-size: 16px;
    color: white;
}

.cropbtnbrowse:hover{
    /* background-color: rgb(0,140,186); */
    background: #F54D4D; /* Lighter red on hover */
    box-shadow: 0 0 8px rgba(255, 255, 255, 0.5); /* Glowing effect */
    color: white;
}

.btngrid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.addproductcontainer .productflex .imageSlider .addPng{
    width: 50px;
    height: auto;
    border-radius: 50%;
}

.addproductcontainer .productflex .imageSlider .imageContain{
    position: relative;
}
.addproductcontainer .productflex .imageSlider .imageContain .removeIcon{
    position: absolute;
    top: 5px;
    right: 0;
    color: red;
}

.addproductcontainer .productflex .editorImg{
    border: 2px solid #290352;
    padding: 15px;
    border-radius: 12px !important;
    width: 300px;
    height: auto;
    transition: .5s;
}

.addproductcontainer .productflex .editorImg:hover{
    scale: 1.1;
}

.displayContent{
    display: flex;
    align-items: start;
    flex-direction: column;
    line-height: 2.6rem;
}

.addproductcontainer .productflex .imageSlider{
    display: flex;
    gap:5px;
    margin: 20px;
}

.addproductcontainer .productflex .imageSlider img{
    width: 100px;
    height: auto;
}

.addproductcontainer .iconContainer{

  background-color: red;
    color: white !important;
    padding: 20px;
    font-size: 20px;
    position: absolute;
    bottom: 50px;
    right: 30px;
    border-radius: 50%; /* Makes it a circle */
    width: 60px; /* Set a fixed width */
    height: 60px; /* Set a fixed height equal to width */
    display: flex; /* Use flex to center the content */
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    
}
.addproductcontainer .iconContainer:hover{
    background: linear-gradient(180deg, #290352 0%, #052546 35.5%, #011021 100%);
}

.addproductcontainer .productflex .contentdiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    box-sizing: border-box;
}
.addproductcontainer .productflex .contentdiv.edit{
    display: grid;
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: center;
    align-items: start;
}

.addproductcontainer .productflex .imageSlider {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    flex-basis: 50%;
    /* background-color: red; */
}


.addproductcontainer .productflex .contentdiv .contentdivrow {
    display: flex;
    
   /* align-items: center; */

    padding: 15px 0px;
    width: 100%;
    transition: background-color 0.3s ease, border-radius 0.3s ease;
    flex-direction: column;
    /* background-color: red; */
}

.addproductcontainer .productflex .contentdiv .contentdivrow input, 
.addproductcontainer .productflex .contentdiv .contentdivrow textarea {
    /* display: flex;
    flex-direction: column; */
    width: 100%;
    max-width: 280px;
    margin-right: 20px;
    margin-top: 10px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    border-radius: 4px;
border: 0.8px solid #B3B3B3;
background: #EEE;
}

.addproductcontainer .productflex .contentdiv .contentdivrow input:focus, 
.addproductcontainer .productflex .contentdiv .contentdivrow textarea:focus {
    border-color: #4a90e2;
    box-shadow: 0px 0px 5px rgba(74, 144, 226, 0.5);
}

.addproductcontainer .productflex .contentdiv .contentdivrow label {
    font-size: 18px;
    color: #333;
    display: flex;
    align-items: center;
    margin-right: 10px;
}

.addproductcontainer .productflex .contentdiv .contentdivrow label .required {
    /* color: red; */
    margin-left: 5px;
}

@media only screen and (max-width : 1050px){
    .addproductcontainer{
        flex-direction: column;
        align-items: center;
        
    }
    .addproductcontainer .productflex{
        align-items: center;
    }

    .addproductcontainer .productflex .contentdiv{
        align-items: center;
        width: 100%;
    }
    .addproductcontainer .productflex .imageSlider img{
        width: 80px;
        height: auto;
    }
    .addproductcontainer .productflex .imageSlider{
        gap: 15px;
    }
    /* .addproductcontainer .productflex .contentdiv {
        align-items: center;
    } */
    .addproductcontainer .productflex .contentdiv .contentdivrow {
        /* display: flex; */
        align-items: center;
    }
}








































.contentdivrow select {
    margin-top: 10px;
    border-radius: 4px;
    border: 0.8px solid #B3B3B3;
    background: #EEE;
    padding: 10px;
    margin-right: 20px;

}



.is_featured{
    display: flex !important;
    align-items: center !important;
    flex-direction: row-reverse !important;
    gap: 10px;
    font-size: 18px;
    justify-content: start !important;

}



.displayContent h1{
    
}
.displayContent p{}
.displayContent h3{}
