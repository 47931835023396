.sign-up-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-container {
    width: 50%;
    padding-right: 20px;
}

.image-container {
    width: 50%;
    text-align: center;
}

.image-container img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

.form-group {
    margin-bottom: 15px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
}

button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    font-size: 18px;
    cursor: pointer;
}

button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .sign-up-container {
        flex-direction: column;
        align-items: stretch;
    }

    .form-container,
    .image-container {
        width: 100%;
        padding: 0;
    }

    .image-container {
        margin-top: 20px;
    }
}