@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Jost:ital,wght@0,100..900;1,100..900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Spline+Sans:wght@300..700&display=swap');


/* Apply DM Sans to body */
body {
    font-family: 'DM Sans', sans-serif;
  }
  
  /* Apply Jost to headings */
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Jost', sans-serif;
  }
  
  /* Apply Lato to paragraph text */
  p {
    font-family: 'Lato', sans-serif;
  }
  
  /* Apply Open Sans to navigation links */
  nav a {
    font-family: 'Open Sans', sans-serif;
  }
  
  /* Apply Poppins to buttons */
  button {
    font-family: 'Poppins', sans-serif;
  }
  
  /* Apply Roboto to general text */
  div {
    font-family: 'jost', sans-serif;
  }
  
  /* Apply Spline Sans to other specific elements */
  .spline-text {
    font-family: 'Spline Sans', sans-serif;
  }
  



.sidediv{
    flex: 2;
    /* box-shadow: 2px 0px 0px 0px #99aff1; */
    
    /* padding-left: 2%;
    padding-right: 2%; */
    /* box-shadow: 1px 0px 2px 2px rgb(161, 160, 160); */

    /* border-radius: 12px; */
background: linear-gradient(180deg, #290352 0%, #052546 18.23%, #011021 100%);
box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    
}
.side_li{
    display: flex;
    align-items: center;
    gap: 12px;
}

.sidediv ul{
    margin-top: 40px;
}

.sidediv h1{
    display: flex;
    align-items: center;
    margin: 0;
    /* font-weight: 400;
    font-size: 26px; */
    background: linear-gradient(90deg, #F27D7F 0%, #9960D2 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-size: 28px;

}


.sidediv ul li {
    /* background-color: red; */
    list-style: none;
    padding-top: 10px;
    /* padding-left: 10px; */
    padding-bottom: 10px;
    /* border-bottom: solid .5px grey; */
    /* box-shadow: 0 2 4 4 black; */
    font-size: 18px;
    transition: .3s;
    /* color: blue; */
    /* padding-left: 30px; */
    color: #D0D1DF;

text-align: center;
}

.sidediv ul li:hover {
    position: relative;
    list-style: none;
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 10px;
    color: white;
    font-size: 20px;
    /* background-color: rgb(112, 191, 217); */
    /* background: linear-gradient(180deg, #452080 0%, #064b7a 18.23%, #022d42 100%); */
    border-left: 4px solid #6396EF;
background: linear-gradient(90deg, rgba(118, 92, 235, 0.27) 0.01%, rgba(255, 255, 255, 0.00) 100%);
backdrop-filter: blur(5px);

}
.side_li.active {
  border-left: 4px solid #6396EF;
  padding-left: 30px;
  background: linear-gradient(90deg, rgba(118, 92, 235, 0.27) 0.01%, rgba(255, 255, 255, 0.00) 100%);
  color: #ffffff;
  font-weight: bold;
}

.companyDiv{
    display: flex;
    /* width: 100%; */
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
    padding : 10px;
    /* background-color: rgb(0,140,186); */
    
    gap: 10px;
    color: white;
}

.companyDiv img{
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.sidediv .bottomlogout{
    gap: 12px;
    display: flex;
    align-items: center !important;
    justify-content: center;
    border-radius: 8px;
    border: 0.6px solid #F5B2B2;
    /* background: #FFEAEA; */
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.05);
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%); 
    padding: 8px 16px;

}
.bottomlogout h2{
    margin: 0;
    color: #EF1717;
font-family: "Neue Haas Unica";
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: normal;
text-transform: capitalize;
}

@media only screen and (max-width: 800px){
    .sidediv{
        display : none
    }
}