.productContent{
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-left: 20px;
    margin : 20px;
    /* background-color: var(--bg); */
}
.productContent .grid{
    /* display: -ms-inline-grid; */
    display: grid;
    grid-template-columns: repeat(auto-fit, 270px);
    grid-auto-rows: 1fr;
    text-align: center;
    justify-content: center;
    gap: 30px;
    justify-items: center;
    width: 100%;
    z-index: 1;
}

.productContent .grid .gridContents .edit{
    position: absolute;
    top: 60px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: #ff0000;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 100;
    font-size: 18px;
    color: #fff;
}

.productContent .grid .gridContents .remove{
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    background-color: red;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 100;
    font-size: 18px;
    color: white;
}

.productContent .grid .gridContents .remove:hover{
    /* background-color: rgb(129, 8, 8); */
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%);
    color: black;

}



.productContent .grid .gridContents{
    border-radius: 12px;
    border: 0.8px solid #EFEFEF;
    background: #FFF;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
    padding: 10px;
    background-color: white;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;

}

.productContent .grid .gridContents img{
    width: 100%;
    height: auto;
    object-fit: cover;
}

.productContent .grid .gridContents .icon{
    font-size: 4rem;
    padding: 20px;
}
.productContent .grid .gridContents .let{
    margin-top: 12px;
    /* padding-right: 20px; */
}
.productContent .grid .gridContents h1{
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%); color: black; 
    font-size: 1rem;
    padding-top: 16px;
}

.productContent .addButton{
    position: fixed;
    display: flex;
    bottom: 20px;
    right: 30px;
    z-index: 100;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    /* padding: 10px; */
    background-color: red;
    border-radius: 50%;
    font-size: 25px;
    color: white;
}
.productContent .grid .gridContents .edit:hover{
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%);
    color: black;

}
.productContent .addButton:hover{
    /* background-color: rgb(129, 8, 8); */
    background: linear-gradient(180deg, #F7EACC 0%, #F8F0DE 100%);
    color: black;
}

/* .productContent .popup.showpopup {
    display: none;
} */

.productContent .popup{
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    z-index: 100;
    /* background-color: black;
    opacity: .6;
    overflow-y: hidden; */
    /* filter: grayscale(50%); */
    /* color: white; */
}

.productContent .popup .innerPopup{
    width: 400px;
    height: 200px;
    background-color: white;
    opacity: 1;
    justify-content: space-around;
    padding: 5px;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 20%;
}

.productContent .popup .innerPopup h1{
    font-size: 24px;
    padding-left: 20px;
    
}

.productContent .popup .innerPopup .buttonDiv{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 35px;
    width: 60%;
    gap: 20px;
    margin-left: 30%;
}
.productContent .popup .innerPopup .buttonDiv button{
    /* background-color: red; */
    border-radius: 10px;
}
.productContent .popup .innerPopup .buttonDiv button:nth-child(1){
    background-color: rgb(246, 103, 56);
    color: white;
    border-radius: 10px;
}
.productContent .popup .innerPopup .buttonDiv button:nth-child(1):hover{
    background-color: red;
    color: white;
    border-radius: 10px;
}
.productContent .popup .innerPopup .buttonDiv button:nth-child(2):hover{
    background-color: grey;
    border-radius: 10px;
    color: white;
}
@media only screen and (max-width: 1000px){
    /* .productContent .grid{
        
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 1fr;
        text-align: center;
        gap: 15px;
        justify-items: center;
        width: 100%;
    } */
}

@media only screen and (max-width: 900px){
    /* .productContent .grid{
        
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: 1fr;
        text-align: center;
        gap: 15px;
        justify-items: center;
        width: 100%;
    } */
}



.gif_loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    flex-direction: column;
}