*{
  padding: 0;
  margin: 0;
  /* font-family: "nunito sans"; */
  box-sizing: border-box;
  text-decoration: none;
}

:root{
  --black : #202224;
  --grey : #bebcbc;
  --green : #00B69B;
  --blue : #4880FF;
  --white : #FFFFFF;
  --bg : #F5F6FA;

}

/* Apply to the entire page or specific container */
::-webkit-scrollbar {
  width: 10px; /* Set scrollbar width */
}

::-webkit-scrollbar-track {
  background: grey; /* Track color */
}

::-webkit-scrollbar-thumb {
  background: rgb(58, 51, 51); /* Thumb color */
  border-radius: 5px; /* Rounded edges for thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #333; /* Slightly lighter black on hover */
}
